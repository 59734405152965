import { inject, Injectable, PLATFORM_ID } from "@angular/core";

import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Subject } from "rxjs";

@Injectable()
export class ThemeService {
  themes = [
    { name: "Light", href: "" },
    { name: "Dark", href: "" }
  ];

  customThemes = [
    "/styles/custom-light-theme.css",
    "/styles/custom-dark-theme.css"
  ];
  linkRef: HTMLLinkElement;
  customThemeLink: HTMLLinkElement;

  private themeChanged = new Subject<any>();
  public themeChangedEmitted = this.themeChanged.asObservable();

  emitTheme(theme: any) {
    this.themeChanged.next(theme);
  }

  private document = inject(DOCUMENT)!;
  private platformId = inject(PLATFORM_ID)!;

  constructor() {}

  setStartTheme() {
    if (isPlatformBrowser(this.platformId)) {
      let theme = this.themes[0];
      try {
        const stored = localStorage.getItem("theme");
        if (stored) {
          theme = JSON.parse(stored);
        }
      } catch (e) {}
      this.linkRef = this.document.createElement("link");
      this.linkRef.rel = "stylesheet";
      this.linkRef.href = theme.href;
      this.linkRef.type = "text/css";
      this.linkRef.href
        ? this.document.querySelector("head")?.appendChild(this.linkRef)
        : {};

      this.customThemeLink = this.document.createElement("link");
      this.customThemeLink.rel = "stylesheet";
      theme.name === "Light"
        ? (this.customThemeLink.href = this.customThemes[0])
        : (this.customThemeLink.href = this.customThemes[1]);
      this.customThemeLink.type = "text/css";
      this.document.querySelector("head")?.appendChild(this.customThemeLink);
    }
  }

  setTheme(theme) {
    theme === "Light" ? (theme = this.themes[0]) : (theme = this.themes[1]);
    localStorage.setItem("theme", JSON.stringify(theme));
    this.linkRef.href = theme.href;
    theme.name === "Light"
      ? (this.customThemeLink.href = this.customThemes[0])
      : (this.customThemeLink.href = this.customThemes[1]);
  }
}
