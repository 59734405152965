import { SettingsState } from "@app/modules/system/store/states/settings.state";
import { createReducer, on } from "@ngrx/store";
import { SettingsActions } from "@app/modules/system/store/actions/settings.actions";

export namespace SettingsReducer {
  const initialState: SettingsState = {
    isOrgSettingsLoading: false
  };

  export const reducer = createReducer(
    initialState,
    on(SettingsActions.getOrganizationSettings, (state) => ({
      ...state,
      isOrgSettingsLoading: true
    })),
    on(SettingsActions.getOrganizationSettingsSuccess, (state, { orgSettings }) => ({
      ...state,
      isOrgSettingsLoading: false,
      orgSettings
    })),
    on(SettingsActions.getOrganizationSettingsFailure, (state) => ({
      ...state,
      isOrgSettingsLoading: false
    }))
  );
}
