import { CookieState } from "@app/modules/system/store/states/cookie.state";
import { createReducer, on } from "@ngrx/store";
import { CookieSearchValues } from "@app/modules/system/models/cookie-search-values";
import { CookieActions } from "@app/modules/system/store/actions/cookie.actions";

export namespace CookieReducer {
  const initialState: CookieState = {};

  export const reducer = createReducer(
    initialState,
    on(CookieActions.getCookieSearchValuesSuccess, (state, { cookieSearchValues }) => ({
      ...state,
      cookieSearchValues: new CookieSearchValues(cookieSearchValues)
    }))
  );
}
