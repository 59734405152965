import { Injectable } from "@angular/core";
import { BackendServer } from "../eager-modules/auth/models/backend-server.type";
import { Undefinable } from "../models/undefinable.type";
import { Nullable } from "@app/models/nullable.type";
import { UrlPrefixService } from "@app/services/url-prefix.service";

@Injectable({ providedIn: "root" })
export class ServerService {

  constructor(private urlPrefixService: UrlPrefixService) {

  }

  saveServer = (server: BackendServer): void => {
    localStorage.setItem("server", JSON.stringify(server));
  };

  getBackendServerUrlWithPort = (): string => {
    const server: BackendServer = this.getBackendServerFromSessionStorage()!;
    return this.urlPrefixService.getHostname().concat(`${ server.serverIp }:${ server.port }`);
  };

  isKarinaServer = (): boolean => {
    const server: BackendServer = this.getBackendServerFromSessionStorage()!;
    if (!server) return false;
    const karinaServers: string[] = ["192.168.180.97", "192.168.180.98", "192.168.180.86"];
    return karinaServers.includes(server.serverIp);
  }

  getBackendServerFromSessionStorage = (): Undefinable<BackendServer> => {
    const server: Nullable<string> = localStorage.getItem("server");
    return server && JSON.parse(server) || undefined;
  };
}
