import { createReducer, on } from "@ngrx/store";
import { RegistryReportViewerState } from "@app/modules/system/store/states/registry-report-viewer.state";
import { RegistryReportViewerActions } from "@app/modules/system/store/actions/registry-report-viewer.actions";

export namespace RegistryReportViewerReducer {
  const initialState: RegistryReportViewerState = {
    isModalOpened: false,
    orgsIds: [],
    loading: false,
    orgType: "NONE",
    collapsed: false,
    innerHTML: "",
    downloadBtnVisible: false,
    fileLoading: false,
    fileJSON: undefined,
    registryBtnVisible: false
  }

  export const reducer = createReducer(
    initialState,
    on(RegistryReportViewerActions.openModal, (state, { orgId, selectedRegistry }) => ({
      ...state,
      orgId,
      selectedRegistry
    })),
    on(RegistryReportViewerActions.initReport, (state, { report, orgType }) => ({
      ...state,
      isModalOpened: true,
      report,
      orgType,
    })),
    on(RegistryReportViewerActions.closeModal, () => ({ ...initialState })),
    on(RegistryReportViewerActions.getReport, (state) => ({
      ...state,
      loading: true,
      innerHTML: "",
      downloadBtnVisible: false,
      registryBtnVisible: false
    })),
    on(RegistryReportViewerActions.getReportFailure, (state) => ({
      ...state,
      loading: false
    })),
    on(RegistryReportViewerActions.getReportSuccess, (state, { innerHTML, fileJSON }) => ({
      ...state,
      loading: false,
      innerHTML,
      downloadBtnVisible: true,
      fileJSON,
      registryBtnVisible: true
    })),
    on(RegistryReportViewerActions.downloadFile, (state) => ({
      ...state,
      fileLoading: true
    })),
    on(RegistryReportViewerActions.downloadFileComplete, (state) => ({
      ...state,
      fileLoading: false
    }))
  )
}
