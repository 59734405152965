import { createAction, props } from "@ngrx/store";

export namespace CookieActions {
  const component = "[Cookie]";

  export const getCookieSearchValues = createAction(
    `${ component } GET_COOKIE_SEARCH_VALUES`
  );
  export const getCookieSearchValuesSuccess = createAction(
    `${ component } GET_COOKIE_SEARCH_VALUES_SUCCESS`, props<{ cookieSearchValues: string[] }>()
  );
}
