import { Injectable } from "@angular/core";
import { AuthBody } from "../eager-modules/auth/models/auth-body.type";

@Injectable({ providedIn: "root" })
export class UrlPrefixService {
  private urlPrefix: string;

  constructor() {
    this.urlPrefix = this.getUrlPrefixFromSessionStorage();
  }

  saveUrlPrefix = (authBody: AuthBody): void => {
    const url: string = window.location.protocol
      .concat("//")
      .concat(this.getHostname())
      .concat(`${ authBody.serverIp }:${ authBody.port }`)
      .concat("/api/");
    localStorage.setItem("urlPrefix", url);
    this.urlPrefix = url;
  };

  getHostname = (): string => !["localhost"].includes(window.location.hostname)
    ? window.location.hostname.concat("/proxy/")
    : "";

  private getUrlPrefixFromSessionStorage = () => localStorage.getItem("urlPrefix") || "";

  getUrlPrefix = (): string => this.urlPrefix;

}
