export const environment = {
  production: false,
  version: require('../../package.json').version,
  deprecatedBackEnd: {
    api: ['192.168.180.240'],
    ports: ['8080'],
    actualFrontend: { domain: 'default', port: 4444 }
  },
  actualFrontend: { domain: 'default', ports: [4040]} // correct version for work on 192.168.180.240:4206
};
