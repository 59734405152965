import { ActionReducerMap } from "@ngrx/store";
import { SystemState } from "../states/system.state";
import { registryReportViewerNode } from "@app/modules/system/store/states/registry-report-viewer.state";
import { RegistryReportViewerReducer } from "@app/modules/system/store/reducers/registry-report-viewer.reducer";
import { periodInfoNode } from "@app/modules/system/store/states/period-info.state";
import { PeriodInfoReducer } from "@app/modules/system/store/reducers/period-info.reducer";
import { organizationNode } from "@app/modules/system/store/states/organization.state";
import { OrganizationReducer } from "@app/modules/system/store/reducers/organization.reducer";
import { cookieNode } from "@app/modules/system/store/states/cookie.state";
import { CookieReducer } from "@app/modules/system/store/reducers/cookie.reducer";
import { settingsNode } from "@app/modules/system/store/states/settings.state";
import { SettingsReducer } from "@app/modules/system/store/reducers/settings.reducer";

export const systemNode = "system";

export namespace SystemReducer {
  export const reducer: ActionReducerMap<SystemState, any> = {
    [registryReportViewerNode]: RegistryReportViewerReducer.reducer,
    [periodInfoNode]: PeriodInfoReducer.reducer,
    [organizationNode]: OrganizationReducer.reducer,
    [cookieNode]: CookieReducer.reducer,
    [settingsNode]: SettingsReducer.reducer
  };
}
