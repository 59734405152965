import { Component, OnInit } from '@angular/core';
import { ExcelExportService } from '@app/shared/services/export-excel.service';

@Component({
  selector: 'app-reconciliation-statements-protocol',
  templateUrl: './reconciliation-statements-protocol.component.html',
  styleUrls: ['./reconciliation-statements-protocol.component.css']
})
export class ReconciliationStatementsProtocolComponent implements OnInit {

  fileList: any[];
  protocolTitle: string;

  constructor(
    private excelExportService: ExcelExportService
  ) { }

  ngOnInit() { 
    let stored;
    localStorage.getItem('reconciliationStatements') ? stored = JSON.parse(localStorage.getItem('reconciliationStatements')) : {};
    this.fileList = stored.pop().results;
    this.protocolTitle = 'Сверка выписок';
  }

  print() {
    window.print();
  }

  download() {
    this.excelExportService.exportAsExcelFile(document.getElementById('exportable'), 'Результаты выгрузки');
  }

}
