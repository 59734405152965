import { Injectable } from "@angular/core";
import { Role, User } from "@app/shared/models/user";
import { HttpService } from "@app/shared/services/http.service";
import { ClrDatagridStateInterface } from "@clr/angular";
import { Organization } from "../models/organization";
import { UserRegistrationBody } from "@app/lazy-loading-modules/user-info/types/user-registration-body.type";
import { from, Observable } from "rxjs";
import { HttpResponse } from "@angular/common/http";
import { UserEditBody } from "@app/lazy-loading-modules/user-info/types/user-edit-body.type";
import { UsersTable } from "@app/lazy-loading-modules/user-info/models/users-table";
import { SortService } from "@app/shared/services/sort.service";
import { PostfixConfigurationService } from "@app/shared/services/postfix-configuration.service";


@Injectable({ providedIn: "root" })
export class UserService {

  constructor(private httpService: HttpService, private sortService: SortService) {}

  getAllowedOrgs(): Promise<Organization[]> {
    return this.httpService.get("users/self/organizations")
      .then(response => Promise.resolve(response.body as Organization[]))
      .catch(this.handleError);
  }

  setRoles(userId: number, rolesIds: number[]) {
    return this.httpService.put("users/" + userId + "/roles", JSON.stringify({ roleId: rolesIds }))
      .then(() => Promise.resolve(true))
      .catch(this.handleError);
  }

  changePassword(userId: number, password: string) {
    const body = {
      password
    };
    return this.httpService.put("users/" + userId + "/changePassword", JSON.stringify(body))
      .then(() => Promise.resolve(true))
      .catch(this.handleError);
  }

  unlockUser = (userId: number): Observable<HttpResponse<void>> =>
    from(this.httpService.put(`users/${ userId }/allowAccess`));


  lockUser = (userId: number): Observable<HttpResponse<void>> =>
    from(this.httpService.put(`users/${ userId }/denyAccess`));


  editUser = (userId: number, body: UserEditBody): Observable<HttpResponse<User>> =>
    from(this.httpService.put("users/" + userId, JSON.stringify(body)));


  getUser(userId: number): Promise<User> {
    return this.httpService.get("users/" + userId.toString())
      .then(response => Promise.resolve(response.body as User))
      .catch(this.handleError);
  }

  registryUser = (body: UserRegistrationBody): Observable<HttpResponse<User>> =>
    from(this.httpService.post("users", JSON.stringify(body)));

  getUsersByPromise = async (page: number, size: number, state?: ClrDatagridStateInterface) => {
    const url: string = `users?page=${page}&size=${size}${ this.sortService.getPostfix(state) }`
    return this.httpService.get(url)
      .then(response => Promise.resolve(response.body as User))
      .catch(this.handleError);
  }

  getAllUsers = (state: ClrDatagridStateInterface): Observable<HttpResponse<UsersTable>> => {
    const url: string = `users`.concat(PostfixConfigurationService.createPostfix(state))
    return from(this.httpService.get(url));
  };

  getSelf(): Promise<User> {
    return this.httpService.get("users/self")
      .then(response => Promise.resolve(response.body as User))
      .catch(this.handleError);
  }

  getRoles(): Promise<Role[]> {
    return this.httpService.get("roles")
      .then(response => Promise.resolve(response.body.content as Role[]))
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }
}
