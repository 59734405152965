import { OrganizationState } from "@app/modules/system/store/states/organization.state";
import { createReducer, on } from "@ngrx/store";
import { OrganizationActions } from "@app/modules/system/store/actions/organization.actions";

export namespace OrganizationReducer {
  const initialState: OrganizationState = {
    slaveOrganizations: []
  };

  export const reducer = createReducer(
    initialState,
    on(OrganizationActions.getCurrentOrganizationSuccess, (state, { organization }) => ({
      ...state,
      currentOrganization: organization
    })),
    on(OrganizationActions.getSlaveOrganizationsSuccess, (state, { slaveOrganizations }) => ({
      ...state,
      slaveOrganizations
    })),
    on(
      OrganizationActions.getSlaveOrganizationsFailure,
      OrganizationActions.getCurrentOrganizationFailure,
      (state) => ({
        ...state
      })
    )
  );
}
