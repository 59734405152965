<router-outlet>
</router-outlet>
@if (show) {
  <div class="app-loading">
    <div class="logo"></div>
    <svg class="spinner" viewBox="25 25 50 50">
      <!-- <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/> -->
    </svg>
  </div>
}

<clr-modal
  [(clrModalOpen)]="updateService.openModal"
  >
  <header class="modal-header modal-title">Новая версия</header>
  <section class="modal-body">
    <label>Доступна новая версия приложения, хотите обновиться сейчас?</label>
  </section>
  <footer class="modal-footer">
    <button class="btn btn-outline" (click)="close()">Нет</button>
    <button class="btn btn-primary" (click)="update()">Да</button>
  </footer>
</clr-modal>
