import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "../eager-modules/auth/services/auth.service";

@Injectable({ providedIn: "root" })
export class AuthGuard  {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate = (): boolean => {
    if (this.authService.isLogged()) {
      return true;
    }
    void this.router.navigate(["/auth"]);
    return false;
  };
}
