import { Injectable, OnInit } from "@angular/core";
import { User } from "@app/shared/models/user";
import { Subject } from "rxjs";
import { Organization } from "../models/organization";

@Injectable({ providedIn: "root" })
export class CurrentUserService implements OnInit {
  constructor() {}

  public userLoaded = new Subject<User>();

  public unauthorizedUser = new User(null, true, -1, null, null, null, null, [], 'Неавторизованный_пользователь', null, null, null, null, null, null, null, null, null);
  public currentUser: User;
  public development: boolean;

  allowedOrgs: Organization[] = [];

  ngOnInit() {
    this.currentUser = this.unauthorizedUser;
  }

  ngOnDestroy() {
    this.currentUser = this.unauthorizedUser;
    this.allowedOrgs.length = 0;
  }

  clear() {
    this.currentUser = this.unauthorizedUser;
    this.development = undefined;
    this.userLoaded = new Subject<User>();
  }

  getUserFromCookie(): User {
    const st_user = localStorage.getItem('user');
    if (st_user && st_user != 'undefined') {
      const user = JSON.parse(st_user);
      if (user && user.username !== 'Неавторизованный_пользователь') {
        this.currentUser = user;
        return this.currentUser;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  isDirector() {
    let res = false;
    this.currentUser.roles.forEach(role => {
      if (role.name == 'ROLE_DIRECTOR') {
        res = true;
      }
    });

    return res;
  }

  isAdmin() {
    let res = false;
    this.currentUser.roles.forEach(role => {
      if (role.name == 'ROLE_ADMIN') {
        res = true;
      }
    });

    return res;
  }

  getAllowedOrs() {
    if (!this.allowedOrgs || this.allowedOrgs.length == 0) {
      this.allowedOrgs = JSON.parse(localStorage.getItem('orgs'));
    }
    return this.allowedOrgs;
  }
}
