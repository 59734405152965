import { Injectable } from "@angular/core";

import { HttpService } from "@app/shared/services/http.service";
import { Organization, OrganizationTable } from "@app/shared/models/organization";
import { ClrDatagridStateInterface } from "@clr/angular";
import { CurrentUserService } from "./current-user.service";
import { SettingsService } from "./settings.service";
import { SortService } from "./sort.service";
import { HttpResponse } from "@angular/common/http";
import { CellType } from "@app/shared-modules/simple-table/types/cell.type";
import { Store } from "@ngrx/store";
import { OrganizationSelectors } from "@app/modules/system/store/selectors/organization.selectors";
import { first } from "rxjs";

export type OrgType = "MASTER" | "SLAVE" | "NONE";

@Injectable()
export class OrganizationService {

  constructor(
    private store$: Store,
    private httpService?: HttpService,
    private currentUser?: CurrentUserService,
    private settingsService?: SettingsService,
    private sortService?: SortService
  ) {}

  public get orgType(): Promise<OrgType> {
    return this.getCurrentOrganization().then((org?: Organization) => Promise.resolve(org.synchronizationTypeCode));
  }

  currentOrganization: Organization;
  orgLoading = false;

  slaveOrganizations: Organization[];

  destroy() {
    this.currentOrganization = null;
    this.slaveOrganizations = null;
  }



  private _logoColumn = {
    header: "Организация",
    class: "width-px-150 center",
    customFilter: "organizationFilter",
    hidden: false,
    type: "orgLogo" as CellType,
    align: "center"
  };
  get logoColumn() {
    return this._logoColumn;
  }

  private _syncColumn = {
    header: "Синхронизировано",
    type: "sync" as CellType,
    class: "width-6 center",
    iconTitle: "Синхронизировано",
    hidden: false
  };
  get syncColumn() {
    return this._syncColumn;
  }

  wait(): Promise<boolean> {
    const promise = new Promise(async (resolve) => {
      setTimeout(async () => {
        if (!this.orgLoading) {
          await resolve(true);
        } else {
          await resolve(this.wait());
        }
      }, 100);
    });
    return promise.then(() => Promise.resolve(true));
  }

  async getCurrentOrganization(): Promise<Organization> {
    if (this.currentOrganization) {
      return Promise.resolve(this.currentOrganization);
    } else if (this.orgLoading) {
      await this.wait();
      return Promise.resolve(this.currentOrganization);
    } else {
      this.orgLoading = true;
      this.store$.select(OrganizationSelectors.getCurrentOrganization).pipe(
        first((organization: Organization) => !!organization)
      ).subscribe((organization: Organization): void => {
        this.currentOrganization = organization;
        this.orgLoading = false;
      });
    }
  }

  async sort(orgId: number, array: Organization[]): Promise<Organization[]> {
    let orgs = [];

    try {
      await this.settingsService.getStorageOrg(orgId)
        .then(storage => {
          storage && storage.UIOrganizationLogoSettings && storage.UIOrganizationLogoSettings.organizationLogoSettings ?
            storage.UIOrganizationLogoSettings.organizationLogoSettings.forEach(setting => {
              const org = array.find(o => (o.id === setting.id));
              org ? orgs.push(org) : {};
            })
            : orgs = this.sortByShortName(array);
        })
        .catch(() => {
          orgs = this.sortByShortName(array);
        });

      const others = array.filter(el => (!orgs.find(org => (org.id === el.id))));
      orgs = orgs.concat(others);
    } catch {}


    return Promise.resolve(orgs);
  }

  sortByShortName(array: Organization[]): Organization[] {
    return array.sort((a, b) => {
      if (a.shortName > b.shortName) {
        return 1;
      } else if (a.shortName < b.shortName) {
        return -1;
      } else {
        return null;
      }
    });
  }

  getSlaveOrganizations(organizationId: number, refresh: boolean = false): Promise<Organization[]> {
    if (!this.slaveOrganizations ||
      ((this.currentOrganization && this.currentOrganization.synchronizationTypeCode == "MASTER") && this.slaveOrganizations.length == 0) ||
      refresh) {
      const url = "organizations/" + organizationId + "/slaves";
      return this.httpService.get(url)
        .then(async response => {
          response.body = await this.sort(organizationId, response.body);
          this.slaveOrganizations = response.body;
          return Promise.resolve(response.body as Organization[]);
        })
        .catch(this.handleError);
    } else {
      return Promise.resolve(this.slaveOrganizations);
    }
  }

  getSlaveOrganizationsIds(organizationId: number): Promise<number[]> {
    return this.getSlaveOrganizations(organizationId).then(orgs => {
      const ids = [];
      orgs.forEach(org => ids.push(org.id));
      return Promise.resolve(ids);
    });
  }

  sortOrganizationsIds(orgsIds: number[]): Promise<number[]> {
    return this.getCurrentOrganization()
      .then(org => org.synchronizationTypeCode === "MASTER" ? this.getSlaveOrganizations(org.id).then(orgs => {
        const ids = [];
        orgs.forEach(o => {
          orgsIds.indexOf(o.id) >= 0 ? ids.push(o.id) : {};
        });
        return Promise.resolve(ids);
      }) : Promise.resolve(orgsIds));
  }

  deleteOrganization(orgId: number): Promise<boolean> {
    return this.httpService.delete("organizations/" + orgId)
      .then(() => Promise.resolve(true))
      .catch(this.handleError);
  }

  editOrganization(organization: Organization): Promise<Organization> {
    const body = {
      name: organization.name,
      shortName: organization.shortName,
      ogrn: organization.ogrn,
      inn: organization.inn,
      kpp: organization.kpp,
      postcode: organization.postcode,
      address: organization.address
    };

    return this.httpService.put("organizations/" + organization.id, JSON.stringify(body))
      .then(response => Promise.resolve(response.body as Organization))
      .catch(this.handleError);
  }

  createOrganization(organization: Organization): Promise<Organization> {
    const body = {
      name: organization.name,
      shortName: organization.shortName,
      ogrn: organization.ogrn,
      inn: organization.inn,
      kpp: organization.kpp,
      postcode: organization.postcode,
      address: organization.address,
      masterId: organization.masterOrganizationId
    };
    return this.httpService.post("organizations", JSON.stringify(body))
      .then((response) => Promise.resolve(response.body as Organization))
      .catch(this.handleError);
  }

  getOrganization(orgId: number): Promise<Organization> {
    return this.httpService.get("organizations/" + orgId)
      .then(response => Promise.resolve(response.body as Organization))
      .catch(this.handleError);
  }

  getAllOrganizations(page: number, size: number, state?: ClrDatagridStateInterface): Promise<OrganizationTable> {
    let url = "organizations?page=" + page + "&size=" + size + this.sortService.getPostfix(state);

    return this.httpService.get(url)
      .then(response => Promise.resolve(response.body as OrganizationTable))
      .catch(this.handleError);
  }

  getMasterAndNoneOrganizations = (
    page: number,
    size: number,
    state?: ClrDatagridStateInterface
  ): Promise<OrganizationTable> => {
    const url = `organizations/masters?page=${ page }&size=${ size }${ this.sortService.getPostfix(state) }`;
    return this.httpService.get(url)
      .then((response: HttpResponse<OrganizationTable>) => Promise.resolve(response.body))
      .catch(this.handleError);
  };

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }
}
