import { Injectable } from '@angular/core';
import { isString } from "@app/shared/helper-functions/check-helper";

@Injectable()
export class CheckService {

  constructor() {}
  static isSearchStringIncludeDeniedSymbol(str: string): boolean {
    const deniedSymbols: string[] = ['&', '\\', '`', '|', '{', '}', '^', '%', '!', '?', '#'];
    if (!isString(str)) return false;
    return deniedSymbols.some((sym: string) => str.includes(sym));
  }
}
