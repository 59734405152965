import { Injectable } from '@angular/core';

const EXPRESS_PORT = 3000;
const OWN_EXPRESS = [
  'bernard',
  '192.168.180.240',
  'dolores',
  '192.168.180.241',
  'khv-vc.ru',
  'electro12'
];
const DEFAULT_EXPRESS = '192.168.180.240';

@Injectable()
export class ExpressUrlService {
  private baseUrl: string;
  constructor() {
    this.initBaseUrl();
  }

  initBaseUrl() {
    this.baseUrl = window.location.protocol + '//';

    const host = window.location.host;
    if ( OWN_EXPRESS.find( x => {
      if (host == x) {
        return true;
      }
    } ) ) {
      this.baseUrl += host + '/proxy/' + host + EXPRESS_PORT + '/';
    } else {
      this.baseUrl += DEFAULT_EXPRESS + ':' + EXPRESS_PORT + '/';
    }
  }

}
