export class CookieSearchValues {
  constructor(values: string[]) {
    this.searchValues = values;
  }

  private _searchValues: string[];

  public popValueIfLengthMoreThanTen = (): void => {
    if (this.searchValues.length > 10) this.searchValues.pop();
  };

  public unshiftSearchTextInSearchValues = (searchText: string): void => {
    if (this.searchValues.includes(searchText)) return;
    this.searchValues.unshift(searchText);
  };

  public get searchValues(): string[] {
    return this._searchValues;
  }

  public set searchValues(values: string[]) {
    this._searchValues = [...values];
  }
}
