<div class="page-container">
  <div class="protocol-container">
    <div class="header-button-container">
      @if (fileList) {
        <button
          (click)="print()"
          class="btn btn-primary print-btn"
          >
          <cds-icon shape="printer"></cds-icon> Печать
        </button>
      }
      @if (fileList) {
        <button
          (click)="download()"
          class="btn btn-success download-btn"
          >
          <cds-icon [shape] = "'xls-file'" [size] = "'20'"></cds-icon>
        </button>
      }
    </div>
    <div class="for-print">
      <span class="card-title">{{ protocolTitle }}</span>
      @if (!fileList) {
        <span>Для данного дома загрузок электронных выписок не найдено</span>
      }
      @if (fileList) {
        <table class="table" id="exportable">
          <thead>
            <tr class="table-header">
              <th class="header">Файл</th>
              <th class="header">Результат загрузки</th>
            </tr>
          </thead>
          <tbody class="table-body">
            @for (fileItem of fileList; track fileItem) {
              <tr class="table-row">
                <td class="table-cell">{{ fileItem.fileName }}</td>
                <td class="table-cell">
                  <div [class]="fileItem?.result?.type + '-span'">
                    @if (fileItem?.result?.type === 'success') {
                      <div>
                        <cds-icon shape="success-standard"></cds-icon>
                        {{ fileItem?.result?.message }}
                      </div>
                    }
                    @if (fileItem?.result?.type === 'error') {
                      <div>
                        @if (fileItem?.result?.type === 'error') {
                          <cds-icon
                            shape="error-standard"
                          ></cds-icon>
                        }
                        <div>{{ fileItem?.result?.message }}</div>
                      </div>
                    }
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      }
    </div>
  </div>
</div>
