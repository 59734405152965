import {
  AbstractControl, FormControl, FormGroup, NonNullableFormBuilder, UntypedFormGroup, Validators
} from "@angular/forms";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { ControlMainErrorService } from "@app/services/control-main-error.service";
import { BackendServer } from "@app/eager-modules/auth/models/backend-server.type";
import { AuthFormGroup } from "@app/eager-modules/auth/models/auth-form-group.type";
import { ServerService } from "@app/services/server.service";
import { Store } from "@ngrx/store";
import { AuthApiActions } from "@app/eager-modules/auth/store/actions/auth-api.actions";
import { AuthBody } from "@app/eager-modules/auth/models/auth-body.type";
import { AuthState } from "@app/eager-modules/auth/store/state/auth.state";
import { AuthSelectors } from "@app/eager-modules/auth/store/selectors/auth.selectors";
import { AuthActions } from "@app/eager-modules/auth/store/actions/auth.actions";
import { Undefinable } from "@app/models/undefinable.type";

@Component({
	selector: "app-auth",
	templateUrl: "auth.component.html",
	styleUrls: ["auth.component.css"]
})
export class AuthComponent implements OnInit, OnDestroy {
	authState$: Observable<AuthState>;
	authForm: UntypedFormGroup;
	sub: Subscription = new Subscription();
	isCapsLockActive: boolean = false;
	servers: BackendServer[] = [
		{ name: "Основная", serverIp: "192.168.180.45", port: 8080 },
		{ name: "Копия", serverIp: "192.168.180.241", port: 8080 },
		{ name: "lilith", serverIp: "192.168.180.98", port: 8080 },
		{ name: "Печать", serverIp: "", port: 0 }
	];
	developerServers: BackendServer[] = [
    { name: "Копия 2", serverIp: "192.168.180.241", port: 8089 },
    { name: "Копия 3", serverIp: "192.168.180.241", port: 9080 },
		{ name: "Сергей", serverIp: "192.168.180.58", port: 6660 },
		{ name: "Артем", serverIp: "192.168.180.85", port: 7777 },
		{ name: "Семён", serverIp: "192.168.180.59", port: 8005 },
		{ name: "Игорь", serverIp: "192.168.180.32", port: 8381 },
		{ name: "vlad-vc", serverIp: "176.100.154.63", port: 8080 },
		{ name: "Ввести ip-адрес сервера", serverIp: "192.168.180.", port: 8080 }
	];

	constructor(private fb: NonNullableFormBuilder, private serverService: ServerService, private store$: Store) {
		this.authState$ = store$.select(AuthSelectors.getAllData);
	}

	ngOnInit(): void {
		this.authForm = this.initAuthForm();
		this.sub.add(this.selectedServer.valueChanges.subscribe((server: BackendServer) => {
			this.serverIp.setValue(server.serverIp);
			this.port.setValue(server.port);
		}));
	}

	private initAuthForm = (): FormGroup<AuthFormGroup> => {
		const defaultServer: BackendServer = this.getDefaultServer();
		return this.fb.group<AuthFormGroup>({
			username: this.fb.control("", Validators.required),
			password: this.fb.control("", [Validators.required, Validators.minLength(3)]),
			selectedServer: this.fb.control(defaultServer),
			serverIp: this.fb.control(defaultServer.serverIp, [Validators.required]),
			port: this.fb.control(defaultServer.port, [Validators.required, Validators.maxLength(6)])
		});
	};

	private getDefaultServer = (): BackendServer => {
		const storedServer: Undefinable<BackendServer> = this.serverService.getBackendServerFromSessionStorage();
		return this.servers.some((server: BackendServer): boolean => storedServer?.name === server.name)
			? storedServer
			: this.servers[0];
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

	protected changeServerAndPortVisibility = (): void => {
		this.store$.dispatch(AuthActions.changeServerVisibility());
	};

	protected changePasswordVisibility = (): void => {
		this.store$.dispatch(AuthActions.changePasswordVisibility());
	};

	protected login = (): void => {
		this.store$.dispatch(AuthApiActions.login({ body: this.createAuthBody(), selectedServer: this.getSelectedServer() }));
	};

	private createAuthBody = (): AuthBody => ({
		username: this.username.value,
		password: this.password.value,
		serverIp: this.serverIp.value,
		port: this.port.value
	});

  private getSelectedServer = (): BackendServer => ({
    name: this.selectedServer.value.name,
    serverIp: this.serverIp.value,
    port: this.port.value
  });

	protected isDisabled = (isLoading: boolean): boolean =>
		isLoading ||
		this.authForm?.invalid && this.selectedServer?.value?.name !== "Печать";

	@HostListener("document:keyup", ["$event"])
	readonly checkCapsLockActive = (keyEvent: KeyboardEvent): void => {
		this.isCapsLockActive = !!(keyEvent?.getModifierState?.("CapsLock"));
	};

	@HostListener("document:keydown", ["$event"])
	readonly hotKey = (key: KeyboardEvent): void => {
		if (key.ctrlKey && key.code === "KeyO") {
			key.preventDefault();
			this.insertDeveloperServers();
		}
	};

	private insertDeveloperServers = (): void => {
		this.developerServers.forEach((server: BackendServer): void => {
			!this.servers.includes(server) && this.servers.push(server);
		});
	};

	protected compareSelectedServer = (a: BackendServer, b: BackendServer): boolean => a.name === b.name;

	protected isControlHasErrors = (control: AbstractControl): boolean =>
		ControlMainErrorService.isControlHasErrors(control);

	protected getControlErrorMessage = (control: AbstractControl): string =>
		ControlMainErrorService.getControlMainErrorMessage(control);

	get username(): FormControl<string> {
		return this.authForm.get("username") as FormControl<string>;
	}

	get password(): FormControl<string> {
		return this.authForm.get("password") as FormControl<string>;
	}

	get selectedServer(): FormControl<BackendServer> {
		return this.authForm.get("selectedServer") as FormControl<BackendServer>;
	}

	get serverIp(): FormControl<string> {
		return this.authForm.get("serverIp") as FormControl<string>;
	}

	get port(): FormControl<number> {
		return this.authForm.get("port") as FormControl<number>;
	}
}
