import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";

const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class ExcelExportService {
  constructor() { }

  public exportAsExcelFile(htmlElement, fileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(htmlElement);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'sheet1');
    XLSX.writeFile(workbook, fileName + EXCEL_EXTENSION);
  }
}
