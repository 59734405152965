import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CurrentUserService } from "@app/shared/services/current-user.service";
import { firstValueFrom, Observable } from "rxjs";
import { UrlPrefixService } from "@app/services/url-prefix.service";
import { TokenService } from "@app/services/token.service";
import { OauthResponse } from "@app/eager-modules/auth/models/oauth-response.type";
import { AuthBody } from "@app/eager-modules/auth/models/auth-body.type";

const OAUTH_CLIENT: "lux-web-client" = "lux-web-client";
const OAUTH_SECRET: "secret" = "secret";
const HTTP_OPTIONS: { headers: HttpHeaders } = {
	headers: new HttpHeaders({
		"Authorization": `Basic ${ btoa(`${ OAUTH_CLIENT }:${ OAUTH_SECRET }`) }`,
		"Content-Type": "application/x-www-form-urlencoded"
	})
};

@Injectable({ providedIn: "root" })
export class AuthService {
  private refreshTokenProcess: Promise<string>;

	constructor(
		private urlPrefixService: UrlPrefixService,
		private router: Router,
		private httpClient: HttpClient,
		private cookie: CookieService,
		private tokenService: TokenService,
		private currentUserService: CurrentUserService
	) {}

	isLogged = (): boolean => !!this.tokenService.getAccessToken();

  getAccToken(fresh: boolean = false): Promise<string> {
    return this.checkFresh(fresh)
      .then(() => `bearer ${ this.tokenService.getAccessToken() }`)
      .catch((reason) => Promise.reject(reason));
  }

  private checkFresh(fresh: boolean): Promise<string> {
    const now: Date = new Date();
    const exp: Date = new Date(this.tokenService.getExpireTime());
    if ((fresh || exp < now) && this.tokenService.getRefreshToken()) {
      return this.refreshToken();
    }
    return Promise.resolve("Token ok.");
  }

  login = (authBody: AuthBody): Observable<OauthResponse> => this.httpClient.post<OauthResponse>(
    this.getUrl() + "oauth/token",
    this.createLoginHttpParam(authBody),
    HTTP_OPTIONS
  );

	private createLoginHttpParam = (authBody: AuthBody): HttpParams => new HttpParams()
		.set("username", authBody.username)
		.set("password", authBody.password)
		.set("scope", "read")
		.set("grant_type", "password");

  private refreshToken = (): Promise<string> => {
    const refreshToken: string = this.tokenService.getRefreshToken();
    if (this.refreshTokenProcess) {
      return this.refreshTokenProcess;
    } else {
      this.tokenService.removeBothTokensWithExpireTime();
      this.refreshTokenProcess = firstValueFrom(this.httpClient
        .post(
          this.getUrl() + "oauth/token",
          this.createRefreshTokenHttpParam(refreshToken),
          HTTP_OPTIONS
        ))
        .then((response: OauthResponse) => {
          this.tokenService.saveBothTokensWithExpireTime(response);
          this.refreshTokenProcess = null;
          return "Tokens refreshed";
        })
        .catch((error) => {
          if (!this.router.url.includes("print")) {
            this.logout();
          }
          this.refreshTokenProcess = null;
          return Promise.reject(error);
        });
    }
    return this.refreshTokenProcess;
  };

	private createRefreshTokenHttpParam = (refreshToken: string) => new HttpParams()
		.set("refresh_token", refreshToken)
		.set("grant_type", "refresh_token")
		.set("scope", "read");

	getUrl(): string {
		return this.urlPrefixService.getUrlPrefix();
	}

	logout(): void {
		this.deleteCookies();
		this.currentUserService.clear();
		this.currentUserService.ngOnDestroy();
		void this.router.navigate(["/auth"]);
	}

	private deleteCookies = (): void => {
		localStorage.removeItem("orgs");
		localStorage.removeItem("user");
		localStorage.removeItem("uploadOwnershipsResults");
		localStorage.removeItem("massCreateAccountsProtocol");
		this.tokenService.removeBothTokensWithExpireTime();
		this.cookie.delete("user", "/", null, false, "Lax");
		this.cookie.delete("login", "/", null, false, "Lax");
	};
}
