// noinspection ES6UnusedImports

import { CookieService } from "ngx-cookie-service";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EventBrokerService } from "./shared/services/event-broker.service";
import { HttpService } from "./shared/services/http.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import localeRu from "@angular/common/locales/ru";
import { NgOptimizedImage, registerLocaleData } from "@angular/common";
import { CheckService } from "@app/shared/services/check.service";
import { NoneRoleComponent } from "@app/components/none-role/none-role.component";
import { ThemeService } from "@app/shared/services/theme.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { InterfaceErrorHandler } from "@app/error-handler";
import { UpdateService } from "@app/shared/services/updateService";
import { ErrorSaverService } from "./shared/services/eror-saver.service";
import { NodeServerService } from "./shared/services/express.service";
import { SortService } from "./shared/services/sort.service";
import { ExpressUrlService } from "./shared/services/express-url.service";
import { SettingsService } from "./shared/services/settings.service";
import { OrganizationService } from "./shared/services/organization.service";
import { AuthModule } from "./eager-modules/auth/auth.module";
import { ExcelExportService } from "./shared/services/export-excel.service";
import { StoreModule } from "@ngrx/store";
import { routerReducer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { ClrModalModule } from "@clr/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CdsIconModule } from "@cds/angular";
import { AuthInterceptor } from "@app/interceptors/auth.interceptor";
import { FILE_SAVER, getSaver } from "@app/providers/file-saver.provider";
import {
  MassCreateAccountsProtocolComponent
} from "@app/dictionaries/address/house/components/mass-create-accounts/mass-create-account-protocol.component";

registerLocaleData(localeRu);

@NgModule({
  declarations: [AppComponent, NoneRoleComponent, MassCreateAccountsProtocolComponent],
	imports: [
		BrowserAnimationsModule,
		HttpClientModule,
		AuthModule,
		AppRoutingModule,
		BrowserModule,
		ServiceWorkerModule.register("ngsw-worker.js", {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: "registerWhenStable:30000"
		}),
		StoreModule.forRoot({ router: routerReducer }),
		StoreRouterConnectingModule.forRoot(),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production,
      connectInZone: true
    }),
		EffectsModule.forRoot([]),
		NgxMaskDirective,
		NgxMaskPipe,
		NgOptimizedImage,
		ClrModalModule,
		FormsModule,
		ReactiveFormsModule,
		CdsIconModule
	],
  providers: [
    OrganizationService,
    ExpressUrlService,
    SortService,
    ErrorSaverService,
    { provide: LOCALE_ID, useValue: "ru-RU" },
    EventBrokerService,
    HttpService,
    CookieService,
    SettingsService,
    CheckService,
    ThemeService,
    NodeServerService,
    UpdateService,
    { provide: ErrorHandler, useClass: InterfaceErrorHandler },
    { provide: FILE_SAVER, useFactory: getSaver },
    ExcelExportService,
    provideNgxMask()
		// { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
