import { Injectable } from '@angular/core';
import { NodeServerService } from './express.service';

@Injectable()
export class ErrorSaverService {
  constructor(
    private nodeServerService: NodeServerService
  ) {}

  public transmitError(content: any): Promise<any> {
    return this.nodeServerService.addLog(JSON.stringify(content))
      .then( () => Promise.resolve(true))
      .catch(e => {
        console.log(e);
      });
  }
}
