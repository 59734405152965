import { PeriodStatus } from "@app/modules/system/components/headers/period-info/type/reporting-period-status.type";
import { Period } from "@app/shared/models/calculation";

export const periodInfoNode = "periodInfo";

export type PeriodInfoState = {
  calculationPeriods: Period[];
  periodStatus?: PeriodStatus;
  calculationOrgsIds: number[]
}
