import { Branch } from "./branch";

export class User {
  constructor(
    public email: string,
    public enabled: boolean,
    public id: number,
    public firstName: string,
    public lastName: string,
    public middleName: string,
    public phone: string,
    public roles: Role[],
    public username: string,
    public branchId: number,
    public branchName: string,
    public departmentId: number,
    public departmentName: string,
    public positionId: number,
    public positionName: string,
    public organizationId: number,
    public organizationName: string,
    public branches: Branch [],
    public rolesNames?: string
  ) {}
}

export class Property {
  constructor(
    public id: number,
    public value: string
  ) {}
}

export class Role {
  constructor(
    public id: number,
    public name: string
  ) {}
}

export class Email {
  constructor(
    public address: string
  ) {}
}
