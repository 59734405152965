import { PeriodInfoState } from "@app/modules/system/store/states/period-info.state";
import { createReducer, on } from "@ngrx/store";
import { PeriodInfoActions } from "@app/modules/system/store/actions/period-info.actions";

export namespace PeriodInfoReducer {
	const initialState: PeriodInfoState = {
		calculationOrgsIds: [],
    calculationPeriods: []
	};

	export const reducer = createReducer(
		initialState,
		on(PeriodInfoActions.setPeriodStatus, (state, { periodStatus, calculationOrgsIds }) => ({
			...state,
			periodStatus,
			calculationOrgsIds
		})),
    on(PeriodInfoActions.getCalculationPeriodsSuccess, (state: PeriodInfoState, { periods }) => ({
      ...state,
      calculationPeriods: periods
    }))
	);
}
