import { Component } from "@angular/core";
import { AuthService } from "@app/eager-modules/auth/services/auth.service";

@Component({
  selector: "none-role-component",
  templateUrl: "./none-role.component.html"
})
export class NoneRoleComponent {
  constructor(private authService: AuthService) {}

  logOut() {
    this.authService.logout();
  }
}
