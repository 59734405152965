import { Injectable } from "@angular/core";
import { OauthResponse } from "@app/eager-modules/auth/models/oauth-response.type";

const ACCESS_TOKEN: "access_token" = "access_token";
const REFRESH_TOKEN: "refresh_token" = "refresh_token";
const EXPIRES_IN: "expires_in" = "expires_in";

@Injectable({ providedIn: "root" })
export class TokenService {
  constructor() {}

  getAccessToken = (): string => {
    return localStorage.getItem(ACCESS_TOKEN) || "";
  };

  getRefreshToken = (): string => {
    return localStorage.getItem(REFRESH_TOKEN) || "";
  };

  getExpireTime = (): string => {
    return localStorage.getItem(EXPIRES_IN) || "";
  };

  saveAccessToken = (accessToken: string): void => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
  };

  saveRefreshToken = (refreshToken: string): void => {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  };

  saveExpireTime = (expireTime: number): void => {
    const now: Date = new Date();
    now.setSeconds(now.getSeconds() + expireTime);
    localStorage.setItem(EXPIRES_IN, now.toString());
  };

  removeAccessToken = (): void => {
    localStorage.removeItem(ACCESS_TOKEN);
  };

  removeRefreshToken = (): void => {
    localStorage.removeItem(REFRESH_TOKEN);
  };

  removeExpireTime = (): void => {
    localStorage.removeItem(EXPIRES_IN);
  };

  saveBothTokensWithExpireTime = (oauthResponse: OauthResponse): void => {
    this.saveAccessToken(oauthResponse.access_token);
    this.saveRefreshToken(oauthResponse.refresh_token);
    this.saveExpireTime(oauthResponse.expires_in);
  };

  removeBothTokensWithExpireTime = (): void => {
    this.removeRefreshToken();
    this.removeAccessToken();
    this.removeExpireTime();
  };

}
