export class ErrorLogRecord {
  constructor(
    public message: string,
    public url: string,
    public stack: string,
    public username: string,
    public userId: number,
    public backend: string,
    public count?: string,
    public status?: string,
    public requestUrl?: string
  ) {}
}
