import { AuthState } from "../state/auth.state";
import { createReducer, on } from "@ngrx/store";
import { AuthActions } from "../actions/auth.actions";
import { HttpErrorResponse } from "@angular/common/http";
import { AuthApiActions } from "@app/eager-modules/auth/store/actions/auth-api.actions";

export namespace AuthReducer {
  const initialState: AuthState = {
    isServerAndPortVisible: false,
    isPasswordVisible: false,
    isLoading: false,
    errorMessage: ""
  };

  export const reducer = createReducer(
    initialState,
    on(AuthActions.changePasswordVisibility, (state: AuthState) => ({
      ...state,
      isPasswordVisible: !state.isPasswordVisible
    })),
    on(AuthActions.changeServerVisibility, (state: AuthState) => ({
      ...state,
      isServerAndPortVisible: !state.isServerAndPortVisible
    })),
    on(AuthApiActions.login, (state: AuthState) => ({
      ...state,
      isLoading: true
    })),
    on(AuthApiActions.loginFailure, (state: AuthState, { error }) => ({
      ...state,
      errorMessage: getErrorMessage(error),
      isLoading: false
    })),
    on(
      AuthActions.navigateToTheMainPage,
      AuthActions.navigateToTheMainPageForDirector,
      AuthActions.navigateToThePrintPage,
      () => ({
        ...initialState
      })
    )
  );

  const getErrorMessage = (error: HttpErrorResponse): string => {
    const JDBCError: string = "Unable to acquire JDBC Connection; "
      .concat("nested exception is org.hibernate.exception. ")
      .concat("JDBCConnectionException: Unable to acquire JDBC Connection");
    switch (error.status) {
      case null:
      case 0: {
        return "Нет соединения с сервером";
      }
      case 400:
      case 401: {
        return error["error_description"] === JDBCError
          ? "Нет соединения с исходной базой данных"
          : "Неправильно введён логин/пароль";
      }
      case 403: {
        return error?.error?.["error_description"] || "Доступ запрещён";
      }
      case 500:
      case 501:
      case 502: {
        return "Произошла ошибка сервера";
      }
      default: {
        return "Неизвестная ошибка";
      }
    }
  };
}
