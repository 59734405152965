import { Injectable } from "@angular/core";
import { ClrDatagridComparatorInterface, ClrDatagridStateInterface } from "@clr/angular";
import { CheckService } from "@app/shared/services/check.service";

@Injectable({
	providedIn: "root"
})
export class PostfixConfigurationService {

	constructor() { }

	static createPostfix(state: ClrDatagridStateInterface & { customSort?: object[] }): string {
		let postfix: string = "";
		if (!state) return "";
		if (state.sort && Object.keys(state.sort).length) {
      postfix += this.createSortPostfix(state.sort);
    }
    if (state.customSort?.length) {
      postfix += this.createCustomSortPrefix(state.customSort);
    }
    if (state.filters?.length) {
      postfix += this.createFilterPostfix(state.filters);
    }
		return `?page=${ state?.page?.current - 1 }&size=${ state?.page?.size }${ postfix }`;
	}

	private static createSortPostfix = (sortState: { by: string | ClrDatagridComparatorInterface<any>; reverse: boolean; }): string =>
		`&sort=${ sortState.by },${ sortState.reverse ? "asc" : "desc" }`;

	private static createCustomSortPrefix = (customSort: object[]) =>
		customSort
			.map((s: object): string => `&sort=${ s["by"] },${ s["reverse"] ? "asc" : "desc" }`)
			.reduce((previousValue: string, currentValue: string): string => previousValue.concat(currentValue));

	private static createFilterPostfix = (filterState: any[]): string =>
		filterState
			.filter((el): boolean =>
				el.property !== "tariffType" && el.property !== "organizationFilter" &&
				!el.customHandler && !CheckService.isSearchStringIncludeDeniedSymbol(el["value"])
			)
			.map((el): string => `&${ el["property"] }=${ el["value"] }`)
			.reduce((previousValue: string, currentValue: string): string => previousValue.concat(currentValue));
}
