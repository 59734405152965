import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { User } from "@app/shared/models/user";

export const AuthActions = createActionGroup({
	source: "Auth",
	events: {
		"Change Password Visibility": emptyProps(),
		"Change Server Visibility": emptyProps(),
		"Navigate To The Main Page": emptyProps(),
		"Navigate To The Main Page For Director": props<{ user: User }>(),
		"Navigate To The Print Page": emptyProps()
	}
});
