import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthComponent } from "@app/eager-modules/auth/components/auth/auth.component";
import { CdsIconModule } from "@cds/angular";
import { AsyncPipe, NgClass, NgForOf, NgIf, NgStyle } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { authNode } from "@app/eager-modules/auth/store/state/auth.state";
import { AuthReducer } from "@app/eager-modules/auth/store/reducer/auth.reducer";
import { EffectsModule } from "@ngrx/effects";
import { AuthEffects } from "@app/eager-modules/auth/store/effects/auth.effects";

@NgModule({
  declarations: [AuthComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    CdsIconModule,
    NgIf,
    NgClass,
    NgForOf,
    NgStyle,
    StoreModule.forFeature(authNode, AuthReducer.reducer),
    EffectsModule.forFeature([AuthEffects]),
    AsyncPipe
  ]
})

export class AuthModule {}
