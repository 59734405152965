export enum TAX_PROPERTY {
  TAX = "property.lawsuit.tax-service.enabled",
  TAX_IN_BALANCE = "property.lawsuit.tax-service.tax-in-balance.enabled",
  TAX_IN_LAWSUIT_VALIDATION = "property.lawsuit.tax-service.tax-in-lawsuit-validation.enabled"
}

export type DashboardSettings = {
  [key: string]: {
    description: string;
    values: {
      [role: string]: boolean
    }
  }
}

export type OrgLogoSettings = {
  color: string;
  name: string;
  id: number;
  letterLogo: string;
  shortName: string
}

export type OrgDisplaySettings = {
  organizationLogoSettings: OrgLogoSettings[];
}

export type UtilityGroupSettings = {
  value: number;
}

export type SettingsValue = {
  value: boolean;
}

export type OrganizationSettings = {
  UIDashboardSettings: DashboardSettings;
  UIOrganizationLogoSettings: OrgDisplaySettings;
  UIUtilityGroup: UtilityGroupSettings;
  [TAX_PROPERTY.TAX]: SettingsValue;
  [TAX_PROPERTY.TAX_IN_BALANCE]: SettingsValue;
  [TAX_PROPERTY.TAX_IN_LAWSUIT_VALIDATION]: SettingsValue;
}
