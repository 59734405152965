import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { BackendServer } from "@app/eager-modules/auth/models/backend-server.type";
import { HttpErrorResponse } from "@angular/common/http";
import { AuthBody } from "@app/eager-modules/auth/models/auth-body.type";

export const AuthApiActions = createActionGroup({
	source: "AuthApi",
	events: {
		"Login": props<{ body: AuthBody, selectedServer: BackendServer }>(),
		"Login Success": emptyProps(),
		"Login Failure": props<{ error: HttpErrorResponse }>(),
		"Logout": emptyProps()
	}
});
