import { MainValidationError } from "../models/main-validation-error.enum";
import { AbstractControl } from "@angular/forms";

export class ControlMainErrorService {
  static mainErrorMessageMap: Record<MainValidationError, (error?: any) => string> = {
    [MainValidationError.REQUIRED]: (): string => "Поле обязательно для заполнения",
    [MainValidationError.MIN]: (): string => "Значение поля меньше минимально возможного",
    [MainValidationError.MAX]: (): string => "Значение поля превышает максимально возможное",
    [MainValidationError.MINLENGTH]: (error: { requiredLength: number }): string =>
      `Длина поля меньше минимального значения: ${ error["requiredLength"] }`,
    [MainValidationError.MAXLENGTH]: (error: { requiredLength: number }): string =>
      `Длина поля превышает максимальное значение: ${ error["requiredLength"] }`,
    [MainValidationError.PATTERN]: (): string => "Поле не соответствует шаблону",
    [MainValidationError.EMAIL]: (): string => "Введён некорректный адрес электронной почты"
  };

  static isControlHasErrors = (control: AbstractControl): boolean => control.invalid && (control.dirty || control.touched);

  static getControlMainErrorMessage = (control: AbstractControl): string => {
    if (!control.errors) return "";
    const firstErrorName: MainValidationError = Object.keys(control.errors)[0] as MainValidationError;
    return ControlMainErrorService.mainErrorMessageMap[firstErrorName]?.(control.errors[firstErrorName]) || "";
  };
}
