import { createFeatureSelector, createSelector } from "@ngrx/store";
import { organizationNode, OrganizationState } from "@app/modules/system/store/states/organization.state";
import { Organization } from "@app/shared/models/organization";
import { SystemState } from "@app/modules/system/store/states/system.state";
import { systemNode } from "@app/modules/system/store/reducers/system.reducer";

export namespace OrganizationSelectors {
	const systemState = createFeatureSelector<SystemState>(systemNode);
	const organizationState = createSelector(systemState, (state) => state[organizationNode]);

	export const getSlaveOrganizations = createSelector(
		organizationState,
		(state: OrganizationState) => state.slaveOrganizations
	);

	export const getCurrentOrganization = createSelector(
		organizationState,
		(state: OrganizationState) => state.currentOrganization
	);

	export const getOrgType = createSelector(
		organizationState,
		(state: OrganizationState) => state.currentOrganization?.synchronizationTypeCode
	);

	export const getOrgId = createSelector(
		organizationState,
		(state: OrganizationState) => state.currentOrganization?.id
	);

	export const getSlaveOrganizationsIds = createSelector(
		organizationState,
		(state: OrganizationState) => state.slaveOrganizations?.map((slaveOrganization: Organization) => slaveOrganization.id)
	);

	export const getOrgIdAndOrgType = createSelector(
		OrganizationSelectors.getOrgId,
		OrganizationSelectors.getOrgType,
		(orgId, orgType) => ({ orgId, orgType })
	);
}
