import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ClrDatagridStateInterface } from "@clr/angular";
import { SortService } from "@app/shared/services/sort.service";
import { firstValueFrom } from "rxjs";

@Injectable()
export class NodeServerService {
  private readonly baseUrl: string;
  constructor(
    private httpClient: HttpClient,
    private sortService: SortService
  ) {
    if (window.location.hostname === 'localhost') {
      this.baseUrl = 'http://192.168.180.240:3000';
    } else {
      let endOfUrl = window.location.hostname;
      endOfUrl = endOfUrl.replace('bernard', '192.168.180.240');
      endOfUrl = endOfUrl.replace('dolores', '192.168.180.241');
      endOfUrl = endOfUrl.replace('khv-vc.ru', '192.168.180.241');
      this.baseUrl = '/proxy/' + endOfUrl + ':3000';
    }
  }

  getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return headers;
  }

  addLog( body ): Promise<any> {
    return firstValueFrom(this.httpClient.post( (this.baseUrl + '/log'), body, { headers: this.getHeaders() } ));
  }

  getLog(startDate: string, endDate: string, state: ClrDatagridStateInterface, pageNumber: number = 0, size: number = 50): Promise<any> {
    isNaN(pageNumber) ? pageNumber = 0 : {};
    const url = this.baseUrl + '/log' + '?startDate=' + startDate + '&endDate=' + endDate + this.sortService.getPostfix(state) + '&page=' + pageNumber + '&size=' + size;
    return firstValueFrom(this.httpClient.get( (url), { headers: this.getHeaders() } ))
      .then( data => Promise.resolve(data) )
      .catch( error => Promise.reject(error));
  }
}
