import { Injectable } from "@angular/core";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { filter } from "rxjs";

@Injectable()
export class UpdateService {
  public openModal: boolean = false;

  constructor(private updates: SwUpdate) {
    if (updates.isEnabled) {
      updates.versionUpdates
        .pipe(filter((event): event is VersionReadyEvent => event.type === "VERSION_READY"))
        .subscribe(() => this.openModal = true);
    }
  }

  public update = () => {
    this.updates.activateUpdate().then(() => {
      location.reload();
      this.close();
    });
  }

  public close = () => this.openModal = false;
}
