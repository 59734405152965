import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, filter, forkJoin, from, map, switchMap, tap } from "rxjs";
import { Router } from "@angular/router";
import { AuthApiActions } from "@app/eager-modules/auth/store/actions/auth-api.actions";
import { AuthActions } from "@app/eager-modules/auth/store/actions/auth.actions";
import { ServerService } from "@app/services/server.service";
import { OrganizationService } from "@app/shared/services/organization.service";
import { AuthService } from "@app/eager-modules/auth/services/auth.service";
import { CurrentUserService } from "@app/shared/services/current-user.service";
import { SettingsService } from "@app/shared/services/settings.service";
import { UrlPrefixService } from "@app/services/url-prefix.service";
import { UserService } from "@app/shared/services/user.service";
import { Organization } from "@app/shared/models/organization";
import { User } from "@app/shared/models/user";
import { TokenService } from "@app/services/token.service";
import { OauthResponse } from "@app/eager-modules/auth/models/oauth-response.type";
import { CookieService } from "ngx-cookie-service";

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private urlPrefixService: UrlPrefixService,
    private router: Router,
    private serverService: ServerService,
    private organizationService: OrganizationService,
    private authService: AuthService,
    private currentUserService: CurrentUserService,
    private settingsService: SettingsService,
    private userService: UserService,
    private tokenService: TokenService,
    private cookieService: CookieService
  ) {}

  login$ = createEffect(() => this.actions$.pipe(
    ofType(AuthApiActions.login),
    filter(({ selectedServer }): boolean => selectedServer.name !== "Печать"),
    tap(({ body, selectedServer }): void => {
      this.organizationService.destroy();
      this.serverService.saveServer(selectedServer);
      this.urlPrefixService.saveUrlPrefix(body);
    }),
    switchMap(({ body }) => this.authService.login(body).pipe(
        switchMap((response: OauthResponse) => {
          this.tokenService.saveBothTokensWithExpireTime(response);
          this.cookieService.set("login", body.username, undefined, "/");
          this.clearData();
          return forkJoin([this.getAllowedOrgs(), this.getCurrentUser()]).pipe(
            map(([orgs, user]) => {
              this.saveOrgsAndUserInLocalStorage(orgs, user);
              return this.currentUserService.isDirector()
                ? AuthActions.navigateToTheMainPageForDirector({ user })
                : AuthActions.navigateToTheMainPage();
            })
          );
        }),
        catchError(async (error) => AuthApiActions.loginFailure({ error }))
      )
    )
  ));

  private clearData = (): void => {
    this.currentUserService.ngOnDestroy();
    this.settingsService.clearServiceData();
    localStorage.removeItem("orgs");
    localStorage.removeItem("user");
  };

  private getAllowedOrgs = () => from(this.userService.getAllowedOrgs());

  private getCurrentUser = () => from(this.userService.getSelf());

  private saveOrgsAndUserInLocalStorage = (orgs: Organization[], user: User): void => {
    localStorage.setItem("orgs", JSON.stringify(orgs));
    this.currentUserService.currentUser = user;
    localStorage.setItem("user", JSON.stringify(user));
  };

  selectPrintServer$ = createEffect(() => this.actions$.pipe(
    ofType(AuthApiActions.login),
    filter(({ selectedServer }): boolean => selectedServer.name === "Печать"),
    tap(({ selectedServer }): void => this.serverService.saveServer(selectedServer)),
    map(() => AuthActions.navigateToThePrintPage())
  ));

  navigateToThePrintPage$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.navigateToThePrintPage),
    tap(() => void this.router.navigateByUrl("/print"))
  ), { dispatch: false });


  navigateToTheMainPage$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.navigateToTheMainPage),
    tap((): void => void this.router.navigateByUrl("system"))
  ), { dispatch: false });

  navigateToTheMainPageForDirectorRole$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.navigateToTheMainPageForDirector),
    tap(({ user }): void =>
      void this.router.navigateByUrl(`system/dashboard/${ user.organizationId }/consolidate`)
    )
  ), { dispatch: false });
}
