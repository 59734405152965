import { createFeatureSelector, createSelector } from "@ngrx/store";
import { authNode, AuthState } from "../state/auth.state";

export namespace AuthSelectors {
	const authState = createFeatureSelector<AuthState>(authNode);

	export const getAllData = createSelector(
		authState, (state: AuthState) => ({ ...state })
	);
}
