import { createAction, props } from "@ngrx/store";
import { Organization } from "@app/shared/models/organization";

export namespace OrganizationActions {
  const component = "[Organization]";

  export const getCurrentOrganization = createAction(`${ component } GET_CURRENT_ORGANIZATION`);
  export const getCurrentOrganizationSuccess = createAction(
    `${ component } GET_CURRENT_ORGANIZATION_SUCCESS`,
    props<{ organization: Organization }>()
  );
  export const getCurrentOrganizationFailure = createAction(`${ component } GET_CURRENT_ORGANIZATION_FAILURE`);

  export const getSlaveOrganizations = createAction(`${ component } GET_SLAVE_ORGANIZATIONS`);
  export const getSlaveOrganizationsSuccess = createAction(
    `${ component } GET_SLAVE_ORGANIZATIONS_SUCCESS`,
    props<{ slaveOrganizations: Organization[] }>()
  );
  export const getSlaveOrganizationsFailure = createAction(`${ component } GET_SLAVE_ORGANIZATIONS_FAILURE`);
}
