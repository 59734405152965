import { Injectable } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { CheckService } from '@app/shared/services/check.service';
import { isNullOrUndefined } from '../helper-functions/check-helper';

@Injectable()
export class SortService {

  constructor() {}

  sortArrayBySingleField(arr: any[], field: string, type: 'asc'|'desc')/* : any[] */ {
    arr.sort((a, b) => {
      if (type === 'asc') {
        if (a[field] > b[field]) {
          return 1;
        } else if (a[field] < b[field]) {
          return -1;
        } else {
          return 0;
        }
      } else {
        if (a[field] < b[field]) {
          return 1;
        } else if (a[field] > b[field]) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  }

  getPostfix(state: any): string {
    let postfix = '';
    if (state) {
      if (state.sort) {
        state.sort.by ? postfix += '&sort=' + state.sort.by + ',' + (state.sort.reverse ? 'asc' : 'desc') : {};
      } else if (state.customSort) {
        state.customSort.forEach(s => {
          s.by ? postfix += '&sort=' + s.by + ',' + (s.reverse ? 'asc' : 'desc') : {};
        });
      }

      if (state.filters) {
        state.filters.forEach(el => {
          if (el.property !== 'tariffType' && el.property !== 'organizationFilter') {
            if (!el.customHandler) {
              !CheckService.isSearchStringIncludeDeniedSymbol(el['value']) ? postfix += '&' + el['property'] + '=' + el['value'] : {};
            }
          }
        });
      }
    }
    return postfix;
  }

  sortArray(arr: any, state: ClrDatagridStateInterface) {
    if (state && state.sort) {
      arr.sort((a: any, b: any) => {
        const field: string = state.sort.by.toString();
        if (!state.sort.reverse) {
          if (!a[field] || a[field] < b[field]) {
            return -1;
          } else if (a[field] > b[field] || !b[field]) {
            return 1;
          } else {
            return 0;
          }
        } else if (state.sort.reverse) {
          if (!a[field] || a[field] < b[field]) {
            return 1;
          } else if (a[field] > b[field] || !b[field]) {
            return -1;
          } else {
            return 0;
          }
        }
      });
    }
  }

  filterArray(arr: any[], state: ClrDatagridStateInterface) {
    if (state && state.filters && state.filters.length) {
      arr = arr.filter((sum) => {
        let res = false;
        state.filters.forEach((filter, index) => {
          !isNullOrUndefined(sum[state.filters[index]['property']]) && (sum[state.filters[index]['property']].toString().toLowerCase()).indexOf((<string>filter['value']).toLowerCase()) > -1 ? res = true : res = false;
        });
        return res;
      });
    }
    return arr;
  }

  sortOwnersOrTenants(type: 'tenant'|'owner', array: any): any {
    array.sort((a, b) => {
      let fioA = '';
      let fioB = '';
      switch (type) {
        case 'tenant': {
          fioA = a.apartmentPerson.lastName +
                  (a.apartmentPerson.firstName ? a.apartmentPerson.firstName : '') +
                  (a.apartmentPerson.middleName ? a.apartmentPerson.middleName : '');
          fioB = b.apartmentPerson.lastName +
                  (b.apartmentPerson.firstName ? b.apartmentPerson.firstName : '') +
                  (b.apartmentPerson.middleName ? b.apartmentPerson.middleName : '');
          break;
        }
        case 'owner': {
          if (a.apartmentSubject.apartmentSubjectTypeId === 0) {
            fioA = a.apartmentSubject.lastName +
                  (a.apartmentSubject.firstName ? a.apartmentSubject.firstName : '') +
                  (a.apartmentSubject.middleName ? a.apartmentSubject.middleName : '');
            fioB = b.apartmentSubject.lastName +
                  (b.apartmentSubject.firstName ? b.apartmentSubject.firstName : '') +
                  (b.apartmentSubject.middleName ? b.apartmentSubject.middleName : '');
          } else {
            fioA = a.apartmentSubject.name;
            fioB = b.apartmentSubject.name;
          }
          break;
        }
      }
      if (a.linkKey < b.linkKey) {
        return -1;
      } else if (a.linkKey > b.linkKey) {
        return 1;
      } else
      if (fioA < fioB) {
        return -1;
      } else if (fioA > fioB) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

  sortResidents(arr: any) {
    arr.sort((a, b) => {
      if (a.linkKey < b.linkKey) {
        return 1;
      } else if (a.linkKey > b.linkKey) {
        return -1;
      } else
      if (a.checkOutDate < b.checkOutDate || (!b.checkOutDate && a.checkOutDate)) {
        return 1;
      } else if (a.checkOutDate > b.checkOutDate || (b.checkOutDate && !a.checkOutDate)) {
        return -1;
      } else {
        if (a.checkInDate < b.checkInDate || (!b.checkInDate && a.checkInDate)) {
          return 1;
        } else if (a.checkInDate > b.checkInDate || (b.checkInDate && !a.checkInDate)) {
          return -1;
        } else {
          const fioA = a.apartmentPerson.lastName +
                      (a.apartmentPerson.firstName ? a.apartmentPerson.firstName : '') +
                      (a.apartmentPerson.middleName ? a.apartmentPerson.middleName : '');
          const fioB = b.apartmentPerson.lastName +
                        (b.apartmentPerson.firstName ? b.apartmentPerson.firstName : '') +
                        (b.apartmentPerson.middleName ? b.apartmentPerson.middleName : '');

          if (fioA < fioB) {
            return 1;
          } else if (fioA > fioB) {
            return -1;
          } else {
            return 0;
          }
        }
      }
    });
  }
}
