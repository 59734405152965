import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { PeriodStatus } from "@app/modules/system/components/headers/period-info/type/reporting-period-status.type";
import { Period } from "@app/shared/models/calculation";

export const PeriodInfoActions = createActionGroup({
  source: "PeriodInfo",
  events: {
    "Set Period Status": props<{ periodStatus: PeriodStatus, calculationOrgsIds?: number[] }>(),
    "Get Calculation Periods": emptyProps(),
    "Get Calculation Periods Success": props<{ periods: Period[] }>(),
    "Get Calculation Periods Failure": emptyProps(),
  }
})
