import { Subject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class EventBrokerService {
  private componentEdited = new Subject<any>();
  public componentEditedEmitted = this.componentEdited.asObservable();

  private componentSent = new Subject<any>();
  public componentSentEmitted = this.componentSent.asObservable();

  private modalRendering = new Subject<any>();
  public modalRenderingEmitted = this.modalRendering.asObservable();

  private globStatMessSent = new Subject<any>();
  public globalStatMessSentEmitted = this.globStatMessSent.asObservable();

  emitComponentEdited(obj: {
    mode: "view" | "edit" | "create" | "clearAll";
    obj: any;
    objType: string;
  }) {
    this.componentEdited.next(obj);
  }

  emitComponentSent(component?: any, componentName?: string) {
    this.componentSent.next({ c: component, n: componentName });
  }

  emitModalRendering(type: string, params: any) {
    this.modalRendering.next({ type, params });
  }

  emitGlobStatMessSent(
    message: string,
    stat: "info" | "warning" | "success" | "danger" | "danger-static"
  ) {
    this.globStatMessSent.next({ message, stat });
  }
}
