import { Component } from '@angular/core';

@Component({
  selector: 'mass-create-accounts-protocol',
  template: `
    <div class="main">
      @if (apartmentInfo) {
        <button (click)="print()" class="btn btn-primary">
          <cds-icon shape="printer"></cds-icon> Печать
        </button>
      }
    
      <div class="for-print">
        @if (apartmentInfo) {
          <span class="card-title">{{apartmentInfo[0]?.houseTitle}}</span>
        }
        @if (!apartmentInfo) {
          <span>Не найдено</span>
        }
        @if (apartmentInfo) {
          <table class="table table-compact">
            <thead>
              <tr>
                <th class="left">Квартира</th>
                <th class="left">Собственники</th>
                <th>Общая площадь</th>
                <th>Жилая площадь</th>
                <th style="width:100px">Расчет с общей площади</th>
                <th class="left">Результат</th>
              </tr>
            </thead>
            <tbody>
              @for (apart of apartmentInfo; track apart) {
                <tr>
                  <td class="left" style="width: 150px;">{{apart.apartment}}</td>
                  <td class="left">{{apart.ownersTitle}}</td>
                  <td>{{apart.ACCOUNT_TOTAL_AREA| number: '1.2-2'}}</td>
                  <td>{{apart.ACCOUNT_RESIDENTIAL_AREA| number: '1.2-2'}}</td>
                  <td>{{apart.ACCOUNT_USE_TOTAL_AREA ? 'Да' : 'Нет'}}</td>
                  <td [ngStyle]="{'color': apart.result === 'success' ? '#2f8400' : '#e12200'}" class="left">
                    @if (apart.result === 'error') {
                      <cds-icon shape="error-standard"></cds-icon>
                    }
                    @if (apart.result === 'success') {
                      <cds-icon shape="success-standard"></cds-icon>
                    }
                    {{apart.message}}
                  </td>
                </tr>
              }
            </tbody>
          </table>
        }
      </div>
    </div>
    `,
  styleUrls: [

    './mass-create-accounts.component.css'
  ]
})

export class MassCreateAccountsProtocolComponent {

  apartmentInfo: any[];
  ngOnInit() {
    try {
      this.apartmentInfo = JSON.parse(localStorage.getItem('massCreateAccountsProtocol'));
    } catch {
      this.apartmentInfo = [];
    }
  }

  print() {
    window.print();
  }
}
