import { createAction, props } from "@ngrx/store";
import { OrgType } from "@app/shared/services/organization.service";
import { SafeHtml } from "@angular/platform-browser";
import { DocumentFormatType, ReportInfoFile } from "@app/util-modules/report-viewer/models/document-format.type";
import { RegistryReport } from "@app/modules/system/components/registry/models/registry-report.interface";

export namespace RegistryReportViewerActions {
  const component = "[RegistryReportViewer]";
  export const openModal = createAction(
    `${ component } OPEN_MODAL`,
    props<{ reportType: string, orgId: number, selectedRegistry: number }>()
  );
  export const closeModal = createAction(`${ component } CLOSE_MODAL`);

  export const initReport = createAction(
    `${ component } INIT_REPORT`,
    props<{ report: RegistryReport, orgType: OrgType }>()
  );

  export const getReport = createAction(`${ component } GET_REPORT`, props<{ dataToSave: any }>());
  export const getReportSuccess = createAction(
    `${ component } GET_REPORT_SUCCESS`,
    props<{ innerHTML: SafeHtml, fileJSON: ReportInfoFile }>()
  );
  export const getReportFailure = createAction(`${ component } GET_REPORT_FAILURE`);

  export const downloadFile = createAction(`${ component } DOWNLOAD_FILE`, props<{ documentFormat: DocumentFormatType }>());
  export const downloadFileComplete = createAction(`${ component } DOWNLOAD_FILE_COMPLETE`);

  export const addToRegistry = createAction(`${ component } ADD_TO_REGISTRY`, props<{ data: any }>());
}
