import { Injectable } from "@angular/core";
import { HttpService } from "@app/shared/services/http.service";
import { CurrentUserService } from "./current-user.service";
import { User } from "../models/user";
import { Guid } from "./guid-generator";
import { BehaviorSubject, from, Observable, Subject } from "rxjs";
import { alphaProfiles } from "@app/shared/json/alpha-profiles";
import {
  OrganizationSettings, SettingsValue, TAX_PROPERTY
} from "@app/modules/admin/components/settings/model/settings";
import { HttpResponse } from "@angular/common/http";

export class InterfaceProfile {
  constructor(
    public name: string,
    public settings: {
      header?: any;
      account?: any;
      apartment?: any;
    },
    public id?: string
  ) {}
}

export class InterfaceProfileLink {
  constructor(
    public interfaceProfiles: {
      id: string;
      default: boolean;
      interfaceProfile?: InterfaceProfile;
    }[],
    public organizationId: number = null,
    public branchId: number = null,
    public positionId: number = null,
    public departmentId: number = null,
    public userId: number = null,
    public id: string = (organizationId ? organizationId.toString() : "null") +
    branchId + positionId + departmentId + userId,
    public organizationName: string = null,
    public branchName: string = null,
    public positionName: string = null,
    public departmentName: string = null,
    public userName: string = null
  ) {}
}

export class OrganizationSet {
  constructor(public organizationStorage: any = {}) {}

  public getOrgLoading = (orgId: number): "loaded" | "loading" | "notLoaded" => this.organizationStorage[orgId]
    ? this.organizationStorage[orgId].loading
    : "notLoaded";

  public setOrgLoading(orgId: number, loading) {
    this.organizationStorage[orgId]
      ? (this.organizationStorage[orgId].loading = loading)
      : (this.organizationStorage[orgId] = { loading });
  }

  public setOrgStorage(orgId: number, storage) {
    this.organizationStorage[orgId] = storage;
    this.organizationStorage[orgId].loading = "loaded";
  }

  public getOrgStorage = (orgId: number): any => this.organizationStorage[orgId] ? this.organizationStorage[orgId] : {};
}

@Injectable()
export class SettingsService {
  constructor(
    private httpService: HttpService,
    private currentUserService: CurrentUserService
  ) {}


  dashboardSettings = new Map([
    ["showTransfer", { value: true, description: "Отображать перебросы" }]
  ]);

  public organizationSet: OrganizationSet = new OrganizationSet();

  taxInLawsuitValidationEnabledMap$ = new BehaviorSubject<{ [orgId: number]: SettingsValue }>(undefined);
  taxServiceEnabledMap$ = new BehaviorSubject<{ [orgId: number]: SettingsValue }>(undefined);
  storedRepresentativeId: number;
  myStorageLoading: "loading" | "loaded" | "notLoaded" = "notLoaded";
  myStorage: any;
  activeProfileChanges = new Subject();
  fields = [
    "userId",
    "departmentId",
    "positionId",
    "branchId",
    "organizationId"
  ];
  storageCommon: {
    interfaceProfilesStorage: {
      interfaceProfiles: InterfaceProfile[];
    };
    interfaceProfileLinksStorage: {
      interfaceProfileLinks: InterfaceProfileLink[];
    };
  };

  clearServiceData() {
    this.httpService.swaggerData = null;
    this.storedRepresentativeId = null;
    this.organizationSet = new OrganizationSet();
    this.storageCommon = null;
    this.myStorageLoading = "notLoaded";
    this.myStorage = null;
  }

  async getDashboardSettingsValue(settings: any, orgId: number) {
    !orgId ? (orgId = this.currentUserService.currentUser.organizationId) : {};
    await this.getStorageOrg(orgId);
    Object.keys(settings).forEach((key) => {
      settings[key] = this.dashboardSettings.get(key).value;
    });
  }

  async wait(type: "my", orgId?: number): Promise<boolean> {
    const promise = new Promise(async (resolve) => {
      setTimeout(async () => {
        const loading = this.myStorageLoading;
        if (loading === "loaded") {
          await resolve(true);
        } else {
          await resolve(this.wait(type, orgId));
        }
      }, 100);
    });
    return promise.then(() => Promise.resolve(true));
  }


  setLocalSettings(
    structure: OrganizationSettings,
    orgId: number = this.currentUserService.currentUser.organizationId
  ) {
    this.organizationSet.setOrgStorage(orgId, structure);
    this.taxServiceEnabledMap$.next({
      ...this.taxServiceEnabledMap$.value,
      [orgId]: { value: structure[TAX_PROPERTY.TAX]?.value || true }
    });
    this.taxInLawsuitValidationEnabledMap$.next({
      ...this.taxInLawsuitValidationEnabledMap$.value,
      [orgId]: {
        value: structure[TAX_PROPERTY.TAX_IN_LAWSUIT_VALIDATION]?.value || true
      }
    });
    if (structure.UIDashboardSettings) {
      Object.keys(structure.UIDashboardSettings).forEach((key) => {
        let flag = false;
        this.currentUserService.currentUser.roles.forEach((userRole) =>
          Object.keys(structure.UIDashboardSettings[key]["values"]).forEach((roleKey) => {
            if (userRole.name === roleKey && structure.UIDashboardSettings[key]["values"][roleKey]) {
              flag = true;
            }
          })
        );
        this.dashboardSettings.get(key).value = flag;
      });
    }
  }

  getOrganizationSettings = async (orgId: number): Promise<any> => {
    const response = await this.getStorageOrg(this.currentUserService.currentUser.organizationId)
      .catch((error) => this.handleError(error));
    if (response) {
      const orgLogoSettings = response?.UIOrganizationLogoSettings?.organizationLogoSettings;
      const res = orgLogoSettings?.find((org) => org.id === orgId);
      return Promise.resolve(res);
    }
  };


  deleteUserStorage = (orgId: number, userId: number): Observable<HttpResponse<void>> => {
    const url: string = `storage/json/users?organizationId=${ orgId }&userId=${ userId }&key=localStorage`;
    return from(this.httpService.delete(url));
  };

  getStorageUser(orgId: number, userId: number, key?: string): Promise<any> {
    const url =
      "storage/json/users?organizationId=" +
      orgId +
      "&userId=" +
      userId +
      (key ? "&key=" + key : "");

    return this.httpService
      .get(url)
      .then((response) => Promise.resolve(response.body))
      .catch(this.handleError);
  }

  deleteStorageOrg(orgId: number, key: string): Promise<boolean> {
    const url =
      "storage/json/organizations?organizationId=" + orgId + "&key=" + key;

    return this.httpService
      .delete(url)
      .then(() => Promise.resolve(true))
      .catch(this.handleError);
  }

  setLocalStorage(orgId: number, data: any) {
    if (data) {
      const notMapData: any = {};
      Array.from(data.keys()).forEach((key: string) => {
        notMapData[key] = data.get(key);
      });
      let saved: any = localStorage.getItem("settings");
      if (saved) {
        saved = JSON.parse(saved);
        saved.find((set) => set.orgId == orgId)
          ? (saved.find((set) => set.orgId == orgId).value = notMapData)
          : saved.push({ orgId, value: notMapData });
        localStorage.setItem("settings", JSON.stringify(saved));
      } else {
        localStorage.setItem(
          "settings",
          JSON.stringify([{ orgId, value: notMapData }])
        );
      }
    }
  }

  getStorageOrg = async (orgId: number): Promise<OrganizationSettings> => {
    const loading = this.organizationSet.getOrgLoading(orgId);
    if (loading === "notLoaded") {
      this.organizationSet.setOrgLoading(orgId, "loading");
      const response = await this.getOrgSettings(orgId).catch((error) => {
        this.organizationSet.setOrgLoading(orgId, "notLoaded");
        this.handleError(error);
      });
      if (response) {
        Object.keys(response).length ? this.setLocalSettings(response, orgId) : {};
        return Promise.resolve(response);
      }
    } else if (loading === "loaded") {
      return Promise.resolve(this.organizationSet.getOrgStorage(orgId));
    }
  };

  getOrgSettings = (orgId: number): Promise<OrganizationSettings> => {
    const url = `storage/json/organizations?organizationId=${ orgId }`;
    return this.httpService.get(url)
      .then((response: HttpResponse<OrganizationSettings>) => Promise.resolve(response.body));
  };

  createStorageOrg(orgId: number, key: string, value: any): Promise<any> {
    return this.httpService
      .post(
        "storage/json/organizations?organizationId=" + orgId + "&key=" + key,
        JSON.stringify(value)
      )
      .then((response) => Promise.resolve(response.body))
      .catch(this.handleError);
  }

  editStorageOrg(orgId: number, key: string, value: any): Promise<any> {
    return this.httpService
      .put(
        "storage/json/organizations?organizationId=" + orgId + "&key=" + key,
        JSON.stringify(value)
      )
      .then((response) => Promise.resolve(response.body))
      .catch(this.handleError);
  }

  setRepresentativeId(id: number) {
    if (id && id !== this.storedRepresentativeId && this.myStorage) {
      if (!this.myStorage["lawsuit.representative"]) {
        void this.createStorageMy("lawsuit.representative", { id });
      } else {
        void this.editStorageMy("lawsuit.representative", { id });
      }
      this.storedRepresentativeId = id;
    }
  }

  async getRepresentativeId(): Promise<number> {
    if (!this.storedRepresentativeId && this.myStorageLoading === "notLoaded") {
      return this.getStorageMy().then((value) => {
        this.storedRepresentativeId = value["lawsuit.representative"]
          ? value["lawsuit.representative"].id
          : null;
        return Promise.resolve(this.storedRepresentativeId);
      });
    } else if (this.myStorageLoading === "loading") {
      await this.wait("my");
      return Promise.resolve(this.storedRepresentativeId);
    } else {
      return Promise.resolve(this.storedRepresentativeId);
    }
  }

  async getStorageMy(key?: string): Promise<any> {
    if (this.myStorageLoading === "notLoaded") {
      this.myStorageLoading = "loading";
      const url = "storage/json/my" + (key ? "?key=" + key : "");

      return this.httpService
        .get(url)
        .then((response) => {
          this.myStorage = response.body;
          this.storedRepresentativeId =
            response.body && response.body["lawsuit.representative"]
              ? response.body["lawsuit.representative"].id
              : null;
          this.myStorageLoading = "loaded";
          return Promise.resolve(response.body);
        })
        .catch((error) => {
          this.myStorageLoading = "notLoaded";
          this.handleError(error);
        });
    } else if (this.myStorageLoading === "loaded") {
      return Promise.resolve(this.myStorage);
    } else {
      const timer = setInterval(() => {
        if (this.myStorageLoading === "loaded") {
          clearInterval(timer);
          return Promise.resolve(this.myStorage);
        }
      }, 200);
      return timer;
    }
  }

  async createStorageMy(key: string, value: any): Promise<any> {
    return this.httpService
      .post("storage/json/my?key=" + key, JSON.stringify(value))
      .then((response) => Promise.resolve(response.body))
      .catch(this.handleError);
  }

  async editStorageMy(key: string, value: any): Promise<any> {
    return this.httpService
      .put("storage/json/my?key=" + key, JSON.stringify(value))
      .then((response) => Promise.resolve(response.body))
      .catch(this.handleError);
  }

  setActiveProfile(profileId: string): Promise<any> {
    const request = this.myStorage.activeProfile
      ? this.editStorageMy("activeProfile", { id: profileId })
      : this.createStorageMy("activeProfile", { id: profileId });
    return request.then((response) => {
      this.myStorage["activeProfile"] = response["activeProfile"];
      this.activeProfileChanges.next(null);
      return Promise.resolve();
    });
  }

  isSame(fieldIndex: number, link: InterfaceProfileLink, user: User): boolean {
    let value = true;
    for (let ind = 0; ind < fieldIndex; ind++) {
      const field = this.fields[ind];
      link[field] &&
      link[field] !== (field === "userId" ? user.id : user[field])
        ? (value = false)
        : {};
    }
    return value;
  }

  // возвращает актуальные для пользователя связи
  getNeededLinks(
    links: InterfaceProfileLink[],
    user: User,
    fieldIndex: number = 0
  ): InterfaceProfileLink[] {
    let needed: any[];
    const field = this.fields[fieldIndex];
    needed = links.filter((link) =>
      (link[field] === user[field === "userId" ? "id" : field]
        || (!link[field] && !links.find((l) => l[field] === user[field === "userId" ? "id" : field])))
      && this.isSame(fieldIndex, link, user)
    );
    needed.length === 1 && !this.isSame(this.fields.length, needed[0], user)
      ? (needed = [])
      : {};
    (needed.length > 1 || !needed.length) &&
    fieldIndex + 1 !== this.fields.length
      ? (needed = this.getNeededLinks(
        needed.length ? needed : links,
        user,
        fieldIndex + 1
      ))
      : {};
    return needed;
  }

  // возвращает текущий активный(при отсутствии дефолтный) профиль текущего пользователя(либо того, который в
  // параметрах)
  async getInterfaceProfile(
    user: User = this.currentUserService.currentUser
  ): Promise<InterfaceProfile> {
    await this.getStorageCommon();
    return this.getInterfaceProfileLinks().then((links) => {
      const neededLinks = this.getNeededLinks(links, user);
      return neededLinks.length
        ? this.getInterfaceProfiles().then(async (profiles) => {
          let activeProfile = null;
          await this.getStorageMy().then((storage) => {
            storage && storage.activeProfile
              ? (activeProfile = storage.activeProfile)
              : {};
          });
          return Promise.resolve(
            profiles.find((p) =>
              activeProfile &&
              neededLinks[0].interfaceProfiles.find(
                (p) => p.id === activeProfile.id
              )
                ? p.id === activeProfile.id
                : p.id ===
                neededLinks[0].interfaceProfiles.find((p) => p.default).id
            )
          );
        })
        : Promise.resolve(null);
    });
  }

  // возвращает все профили, которые доступны пользователю, из первой попавшейся связи
  async getInterfaceProfilesOfUser(
    user: User = this.currentUserService.currentUser
  ): Promise<{ id: string; default: boolean; interfaceProfile?: InterfaceProfile }[]> {
    await this.getStorageCommon();
    return this.getInterfaceProfileLinks().then((links) => {
      const neededLinks = this.getNeededLinks(links, user);
      return neededLinks.length
        ? this.getInterfaceProfiles().then((profiles) => {
          neededLinks[0].interfaceProfiles.forEach((p) => {
            p.interfaceProfile = profiles.find((prof) => prof.id === p.id);
          });
          return Promise.resolve(
            neededLinks ? neededLinks[0].interfaceProfiles : []
          );
        })
        : Promise.resolve(null);
    });
  }

  getInterfaceProfileLinks(): Promise<InterfaceProfileLink[]> {
    return Promise.resolve(
      this.storageCommon && this.storageCommon.interfaceProfileLinksStorage
        ? this.storageCommon.interfaceProfileLinksStorage.interfaceProfileLinks
        : []
    );
  }

  async deleteInterfaceProfileLink(
    linkId: string
  ): Promise<InterfaceProfileLink[]> {
    await this.getStorageCommon();
    const links =
      this.storageCommon && this.storageCommon.interfaceProfileLinksStorage
        ? this.storageCommon.interfaceProfileLinksStorage.interfaceProfileLinks.map(
          (l) => l
        )
        : [];
    links.splice(
      links.findIndex((pr) => linkId === pr.id),
      1
    );
    return this.editStorageCommon("interfaceProfileLinksStorage", {
      interfaceProfileLinks: links
    })
      .then((response) => {
        this.storageCommon
          ? (this.storageCommon.interfaceProfileLinksStorage =
            response.interfaceProfileLinksStorage)
          : (this.storageCommon = response);
        return Promise.resolve(
          this.storageCommon.interfaceProfileLinksStorage.interfaceProfileLinks
        );
      })
      .catch(this.handleError);
  }

  checkForLinks(links: InterfaceProfileLink[]): boolean {
    let check = true;
    const set = new Set();
    links.forEach((link) => {
      set.has(link.id) ? (check = false) : set.add(link.id);
    });
    return check;
  }

  async editInterfaceProfileLink(
    linkId: string,
    link: InterfaceProfileLink
  ): Promise<InterfaceProfileLink[]> {
    await this.getStorageCommon();
    const links =
      this.storageCommon && this.storageCommon.interfaceProfileLinksStorage
        ? this.storageCommon.interfaceProfileLinksStorage.interfaceProfileLinks.map(
          (l) => l
        )
        : [];
    links[links.findIndex((l) => l.id === linkId)] = link;
    if (this.checkForLinks(links)) {
      return this.editStorageCommon("interfaceProfileLinksStorage", {
        interfaceProfileLinks: links
      })
        .then((response) => {
          this.storageCommon
            ? (this.storageCommon.interfaceProfileLinksStorage =
              response.interfaceProfileLinksStorage)
            : (this.storageCommon = response);
          return Promise.resolve(
            this.storageCommon.interfaceProfileLinksStorage
              .interfaceProfileLinks
          );
        })
        .catch(this.handleError);
    } else {
      return this.handleError("Повторяющее значение для связи");
    }
  }

  async createInterfaceProfileLink(
    link: InterfaceProfileLink
  ): Promise<InterfaceProfileLink[]> {
    await this.getStorageCommon();
    let request;
    const links =
      this.storageCommon && this.storageCommon.interfaceProfileLinksStorage
        ? this.storageCommon.interfaceProfileLinksStorage.interfaceProfileLinks.map(
          (l) => l
        )
        : [];
    links.push(link);
    if (this.checkForLinks(links)) {
      if (
        this.storageCommon &&
        this.storageCommon.interfaceProfileLinksStorage
      ) {
        request = this.editStorageCommon("interfaceProfileLinksStorage", {
          interfaceProfileLinks: links
        });
      } else {
        request = this.createStorageCommon("interfaceProfileLinksStorage", {
          interfaceProfileLinks: links
        });
      }
      return request
        .then((response) => {
          this.storageCommon
            ? (this.storageCommon.interfaceProfileLinksStorage =
              response.interfaceProfileLinksStorage)
            : (this.storageCommon = response);
          return Promise.resolve(
            this.storageCommon.interfaceProfileLinksStorage
              .interfaceProfileLinks
          );
        })
        .catch(this.handleError);
    } else {
      return this.handleError("Повторяющее значение для связи");
    }
  }

  async getInterfaceProfiles(): Promise<InterfaceProfile[]> {
    await this.getStorageCommon();
    return Promise.resolve(
      this.storageCommon && this.storageCommon.interfaceProfilesStorage
        ? JSON.parse(
          JSON.stringify(
            this.storageCommon.interfaceProfilesStorage.interfaceProfiles
          )
        )
        : []
    );
  }

  checkForDeleteProfile(profileId: string): boolean {
    return this.storageCommon.interfaceProfileLinksStorage &&
    this.storageCommon.interfaceProfileLinksStorage.interfaceProfileLinks
      ? !this.storageCommon.interfaceProfileLinksStorage.interfaceProfileLinks.find(
        (link) => link.interfaceProfiles.find((p) => p.id === profileId)
      )
      : true;
  }

  async deleteInterfaceProfile(profileId: string): Promise<InterfaceProfile[]> {
    await this.getStorageCommon();
    if (this.checkForDeleteProfile(profileId)) {
      const index =
        this.storageCommon.interfaceProfilesStorage.interfaceProfiles.findIndex(
          (pr) => profileId === pr.id
        );
      this.storageCommon.interfaceProfilesStorage.interfaceProfiles.splice(
        index,
        1
      );
      return this.editStorageCommon(
        "interfaceProfilesStorage",
        this.storageCommon.interfaceProfilesStorage
      )
        .then((response) => {
          this.storageCommon
            ? (this.storageCommon.interfaceProfilesStorage =
              response.interfaceProfilesStorage)
            : (this.storageCommon = response);
          return Promise.resolve(
            this.storageCommon.interfaceProfilesStorage.interfaceProfiles
          );
        })
        .catch(this.handleError);
    } else {
      return Promise.reject(new Error("На профиль есть ссылка"));
    }
  }

  async editInterfaceProfile(
    profile: InterfaceProfile
  ): Promise<InterfaceProfile[]> {
    await this.getStorageCommon();
    const interfaceProfiles =
      this.storageCommon && this.storageCommon.interfaceProfilesStorage
        ? this.storageCommon.interfaceProfilesStorage.interfaceProfiles.map(
          (l) => l
        )
        : [];
    interfaceProfiles[interfaceProfiles.findIndex((p) => p.id === profile.id)] =
      profile;
    return this.editStorageCommon("interfaceProfilesStorage", {
      interfaceProfiles
    })
      .then((response) => {
        this.storageCommon
          ? (this.storageCommon.interfaceProfilesStorage =
            response.interfaceProfilesStorage)
          : (this.storageCommon = response);
        this.activeProfileChanges.next(null);
        return Promise.resolve(
          this.storageCommon.interfaceProfilesStorage.interfaceProfiles
        );
      })
      .catch(this.handleError);
  }

  checkInterfaceProfileName(profile: InterfaceProfile): boolean {
    if (
      !this.storageCommon ||
      !this.storageCommon.interfaceProfilesStorage ||
      !this.storageCommon.interfaceProfilesStorage.interfaceProfiles
    ) {
      return true;
    }
    return !this.storageCommon.interfaceProfilesStorage.interfaceProfiles.find(
      (pr) => pr.name.trim().toLowerCase() === profile.name.trim().toLowerCase()
    );
  }

  checkInterfaceGuid(profileId: string) {
    if (
      !this.storageCommon ||
      !this.storageCommon.interfaceProfilesStorage ||
      !this.storageCommon.interfaceProfilesStorage.interfaceProfiles
    ) {
      return true;
    }
    return !this.storageCommon.interfaceProfilesStorage.interfaceProfiles.find(
      (pr) => pr.id === profileId
    );
  }

  getGuid(): string {
    const guid = Guid.newGuid().toString();
    return this.checkInterfaceGuid(guid) ? guid : this.getGuid();
  }

  async createInterfaceProfile(
    profile: InterfaceProfile
  ): Promise<InterfaceProfile[]> {
    await this.getStorageCommon();
    let request;
    profile.id = this.getGuid();
    if (this.storageCommon && this.storageCommon.interfaceProfilesStorage) {
      this.storageCommon.interfaceProfilesStorage.interfaceProfiles.push(
        profile
      );
      request = this.editStorageCommon(
        "interfaceProfilesStorage",
        this.storageCommon.interfaceProfilesStorage
      );
    } else {
      request = this.createStorageCommon("interfaceProfilesStorage", {
        interfaceProfiles: [profile]
      });
    }
    return request
      .then((response) => {
        this.storageCommon
          ? (this.storageCommon.interfaceProfilesStorage =
            response.interfaceProfilesStorage)
          : (this.storageCommon = response);
        return Promise.resolve(
          this.storageCommon.interfaceProfilesStorage.interfaceProfiles
        );
      })
      .catch(this.handleError);
  }

  getStorageCommon(key?: string): Promise<any> {
    if (!this.storageCommon) {
      const url = "storage/json/commons" + (key ? "?key=" + key : "");

      return this.httpService
        .get(url)
        .then((response) => {
          const profileSettings = response.body || alphaProfiles;
          !key ? (this.storageCommon = profileSettings) : {};
          return Promise.resolve(profileSettings);
        })
        .catch(this.handleError);
    } else {
      return Promise.resolve(
        key ? this.storageCommon[key] : this.storageCommon
      );
    }
  }

  createStorageCommon(key: string, value: any): Promise<any> {
    return this.httpService
      .post("storage/json/commons?key=" + key, JSON.stringify(value))
      .then((response) => Promise.resolve(response.body))
      .catch(this.handleError);
  }

  editStorageCommon(key: string, value: any): Promise<any> {
    return this.httpService
      .put("storage/json/commons?key=" + key, JSON.stringify(value))
      .then((response) => Promise.resolve(response.body))
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }
}
