import { ReportViewerState } from "@app/util-modules/report-viewer/store/state/report-viewer.state";
import { RegistryReport } from "@app/modules/system/components/registry/models/registry-report.interface";

export const registryReportViewerNode = "registryReportViewer";

export interface RegistryReportViewerState extends Omit<ReportViewerState, "isConfirmPreviewReportModalOpened"> {
  registryBtnVisible: boolean;
  selectedRegistry?: number;
  report?: RegistryReport;
}
