import { createAction, props } from "@ngrx/store";
import { OrganizationSettings } from "@app/modules/admin/components/settings/model/settings";

export namespace SettingsActions {
  const component = "[Settings]";

  export const getOrganizationSettings = createAction(
    `${ component } GET_ORGANIZATION_SETTINGS`
  );
  export const getOrganizationSettingsSuccess = createAction(
    `${ component } GET_ORGANIZATION_SETTINGS_SUCCESS`, props<{ orgSettings: OrganizationSettings }>()
  );
  export const getOrganizationSettingsFailure = createAction(
    `${ component } GET_ORGANIZATION_SETTINGS_FAILURE`
  );
}
