import { ErrorHandler, Injectable, Injector, isDevMode } from "@angular/core";
import * as StackTrace from "stacktrace-js";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { CurrentUserService } from "./shared/services/current-user.service";
import { ErrorSaverService } from "./shared/services/eror-saver.service";
import { ErrorLogRecord as ErrorLogReport } from "./shared/models/error-log";
import { ServerService } from "@app/services/server.service";

export class TransmittedError {
  readonly TIME_FOR_LOG_MIN = 1;
  constructor(
    public additionTime: Date,
    public error: any,
    public errorCounter: number
  ) {}

  isTimeToTransmit() {
    let now = new Date().getTime();
    let addTime = this.additionTime.getTime();

    let timePassedMIn = (now - addTime)/( 1000 * 60 );
    return timePassedMIn > this.TIME_FOR_LOG_MIN;
  }

}

@Injectable()
export class InterfaceErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private serverService: ServerService) {}

  private transmittedReports: TransmittedError[] = [];
  addError(error: any) {
    let transmittedError = new TransmittedError( new Date(), error, 0 );
    this.transmittedReports.push(transmittedError);
  }

  isNoDuplicate(error) {

    let noDuplicates = true;
    let index;
    this.transmittedReports.forEach((transmittedReport, i) => {
      if ( transmittedReport.error.message === error.message ) {
        noDuplicates = false;
        index = i;
      }
    });

    if (!noDuplicates) {
      this.transmittedReports[index].errorCounter ++;
      if (this.transmittedReports[index].isTimeToTransmit()) {
        this.handleError(error, this.transmittedReports[index].errorCounter);
      }
    }
    return noDuplicates;
  }


  handleError(error: any, count?: number) {
    if (!isDevMode() && (count || this.isNoDuplicate(error))) {
      const location = this.injector.get(LocationStrategy);
      const userService = this.injector.get(CurrentUserService);
      const errorSaver = this.injector.get(ErrorSaverService);

      const backend: string = this.serverService.getBackendServerUrlWithPort();

      let url = window.location.host + ( location instanceof PathLocationStrategy? location.path(): '' );
      const message = error.message ? error.message : error.toString();

      let report;
      StackTrace.fromError(error).then(stackFrames => {
        const stackString = stackFrames
          .splice(0, 20)
          .map(function(sf) {
            return sf.toString();
          }).join(' ');
        let user = userService.currentUser;
        report = new ErrorLogReport( message, url, stackString, user? user.username: '', user? user.id: null, backend, count + '');

        this.addError(error);
        void errorSaver.transmitError(report);
        // throw error;
        console.log(error);
      });
    } else {
      // throw error;
      console.log(error);
    }
  }
}
